import {onFind} from "@elements/init-modules-in-scope";

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});

import Collapse from 'bootstrap/js/dist/collapse';
onFind('.collapse',function (element) {
    new Collapse(element, {toggle: false})
});

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

//todo datepicker format
import * as datepicker from '@elements/datepicker';
datepicker.init();

import * as datepickerRange from '@elements/datepicker-range';
datepickerRange.init({openDatepickerTo: true});

import * as Console from '../scripts/console';
Console.init();

import * as reloadForm from "@elements/reload-form";
reloadForm.init();

import * as reactMultiselect from '../scripts/react-multiselect';
reactMultiselect.init();

import * as quickfinder from '../scripts/quickfinder';
quickfinder.init();

import * as interactiveMapInfobox from '../scripts/interactive-map-infobox';
interactiveMapInfobox.init();

import * as weatherIcons from '../scripts/weather-icons';
weatherIcons.init();

import * as weatherChange from '../scripts/weather-change';
weatherChange.init();

import * as account  from '../scripts/account';
account.init();

import * as stickyNav from '../scripts/sticky-nav';
stickyNav.init();

import * as searchOverlay from '../scripts/search-overlay';
searchOverlay.init();

import * as search from '../scripts/search';
search.init();

import * as mobileNav from '../scripts/mobile-nav';
mobileNav.init();

import * as nav from '../scripts/nav';
nav.init();

import * as isClickable from '../scripts/is-clickable';
isClickable.init();

import * as slideTabs from '../scripts/slide-tabs';
slideTabs.init();

import * as actionChanger from '../scripts/action-changer';
actionChanger.init();

import * as formValidation from './form-validation';
formValidation.init();

import * as smoothScroll from './smooth-scroll';
smoothScroll.init();

import * as video from './video';
video.init();

import * as lightbox from '@elements/lightbox';
lightbox.init({
    plugins: ['video'],
    mobileSettings: {
        controls: true,
        showCloseIcon: true
    }
});

import * as typeahead from './typeahead';
typeahead.init();

import * as overlay from './overlay';
overlay.init();

import * as ajaxInclude from './ajaxInclude';
ajaxInclude.init();

import * as heroSlider from './hero-slider';
heroSlider.init();

import * as headerImageSliderMobile from './header-image-slider-mobile';
headerImageSliderMobile.init();

import * as imgSlider from './img-slider';
imgSlider.init();

import * as moodboardSlider from './moodboard-slider';
moodboardSlider.init();

import * as portalSlider from './portal-slider';
portalSlider.init();

import * as webcamGallerySlider from './webcam-gallery-slider';
webcamGallerySlider.init();

import * as lightboxGallery from './lightbox-gallery';
lightboxGallery.init();

import * as dynamicLightbox from './dynamic-lightbox';
dynamicLightbox.init();

import * as datalayerCheckbox from './datalayer-checkbox';
datalayerCheckbox.init();

import * as gaTracking  from './ga-tracking';
gaTracking.init();